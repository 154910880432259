.ButtonFloat, .liveStreamPlayer {
  position: fixed;
  height: 106px;
  width: 48px;
  bottom: 25px;
  right: 31px;
  z-index: 1000;

  // @media screen and (max-width: 1199px) {
  //   bottom: 130px;
  // }

  @media screen and (max-width: 600px) {
    bottom: 40px;
  }
}
.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
}
.liveStreamPlayer {
  position: fixed;
  bottom: 10px;
  right: 15px;
  width: 320px;
  height: 180px;
  z-index: 1000;
}
.ButtonFloatLive {
  position: fixed;
  height: 160px;
  width: 48px;
  bottom: 25px;
  right: 31px;
  z-index: 1000;

  // @media screen and (max-width: 1199px) {
  //   bottom: 130px;
  // }

  @media screen and (max-width: 600px) {
    bottom: 40px;
  }
}

.DividerH5 {
  border: none !important;
  margin: 5px 0 !important;
}
.wrapper {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: block;
}
.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgb(255, 23, 100);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.liveButton {
  background: linear-gradient(131.17deg, #FF1764 0%, #ED3495 94.15%);
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito;
  color: #FFF;
  padding: 0 4px;
  margin-bottom: -5px;
  border-radius: 2px;
}
.logoCircle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgb(255, 23, 100, 0.2);
  -webkit-animation: animation-fp9d2s 0.9s ease-in-out infinite alternate;
  animation: animation-fp9d2s 0.9s ease-in-out infinite alternate;
}
.logo {
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;
  -webkit-animation: animation-fp9d1s 0.9s ease-in-out infinite alternate;
  animation: animation-fp9d1s 0.9s ease-in-out infinite alternate;
  display: block;
}
@-webkit-keyframes animation-fp9d1s {
  from {
      -webkit-transform: scale(1, 1);
  }
  80% {
      -webkit-transform: scale(0.9, 0.9);
  }
  to {
      -webkit-transform: scale(1, 1);
  }
}

@keyframes animation-fp9d1s {
 from {
      transform: scale(0.9, 0.9);;
 }
 to {
     transform: scale(1, 1);
 }
}
@-webkit-keyframes animation-fp9d2s {
  from {
      -webkit-transform: scale(1.2, 1.2);
  }
  80% {
      -webkit-transform: scale(1, 1);
  }
  to {
      -webkit-transform: scale(1, 1);
  }
}

@keyframes animation-fp9d2s {
 from {
      transform: scale(1, 1);;
 }
 to {
     transform: scale(1.2, 1.2);
 }
}
