@font-face {
  font-family: Neue;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-75Bold.otf');
}
@font-face {
  font-family: Neue-Regular;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-65Medium.otf');
}
.paddingContent {
  width: 100%;
  max-width: 1336px;
  margin: 0 auto;
  padding: 48px 0 0;
  @media screen and (max-width: 768px) {
    padding: 12px;
  }
}
.footer {
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.socialSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 310px;
  flex: none;
  @media screen and (max-width: 768px) {
    width: unset;
  }
}
.advertising {
  padding: 16px 0;
}
.advertisingText {
  color: #2E2E2E;
  font-family: Neue-Regular;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
text-transform: uppercase;
}
.advertisingEmail {
  color: #0872CA;
  font-family: Neue;
font-size: 20px;
line-height: 24px; /* 120% */
padding-top: 5px;
}
.socialText {
  color: #929292;
font-family: Neue-Regular;
font-size: 12px;
line-height: 16px; /* 133.333% */
}
.logo {
  width: 204px;
}
.logoVnrWhiteDesktop {
  width: 200px;
  // height: 70px;
  position: relative;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.logoBeeWhiteDesktop {
  width: 220px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.logoVnrWhite {
  position: relative;
  margin: 0 auto;
  display: none;
  @media screen and (max-width: 768px) {
    width: 155px;
    display: block;
  }
}
.footerInformation {
  @media screen and (min-width: 768px) {
    margin-right: 20px;
  }
}
.footerCategory {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 2.14;
  @media screen and (max-width: 768px) {
   flex-direction: column;
   line-height: 24px;
  }
}
.cateTwoColFlex {
  display: flex;
  flex-direction: row;
  min-width: 100px;
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    gap: 20px;
  }
  @media screen and (max-width: 360px) {
    gap: 0;
  }
}
.rootCate {
  min-width: 100px;
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 20px;
  }
  @media screen and (max-width: 360px) {
    gap: 0;
  }
}
.cateAcademySiteFlex {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    gap: 20px;
  }
  @media screen and (max-width: 360px) {
    gap: 0;
  }
}
.footerSocial {
  width: 350px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 400px;
  }
  @media screen and (max-width: 425px) {
    width: 345px;
  }
}
.title {
  color: #2E2E2E;
font-family: Neue-Regular;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
}
.socialList {
  margin: 28px auto 20px;
  @media screen and (max-width: 576px) {
    height: 70px;
  }
}
.iconList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 5px;
    width: 320px;
  }
  div {
    
width: 48px;
height: 48px;
border-radius: 50%;
border: 1px solid rgba(2, 114, 202, 0.20);
background-color: #FFF;
box-shadow: 0px 4px 0px 0px rgba(8, 114, 202, 0.20);
a {
  width: 100%;
  height: 100%;
  display: grid;
}
  }
  svg {
    place-self: center;
    &:hover {
      circle {
        stroke: #EE7938;
      }
      path {
        fill: #EE7938;
      }
      fill: #FFF;
    }
  }
}
.icon {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: #fff;
}
.email {
  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.facebookGroup {
  position: relative;
  width: 350px;
  height: 278px;
  margin: 10px auto 0;
  @media screen and (max-width: 576px) {
    width: 345px;
    margin-top: 20px;
  }
}
.iframeGroupFB {
  border: 'none';
  overflow: 'hidden';
  @media screen and (max-width: 576px) {
    width: 345px;
    margin-top: 20px;
  }
}
.footerContent {
  border-top: 1px solid #D8E1F5;
}
.cateItem {
  padding-bottom: 5px;
  h4 {
    margin: 0;
    font-size: 12px;
    line-height: 16px;

  }
  @media screen and (max-width: 576px) {
    margin-right: unset;
    padding-right: unset;
    max-width: 100%;
  }
}
.siteList {
  display: none;
  @media screen and (max-width: 576px) {
    display: unset;
  }
}
.websiteSiteList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0;
  padding: 12px 0;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  @media screen and (max-width: 576px) {
    border-bottom: unset;
    flex-direction: column;
    margin: 8px 0;
  }
}
.siteListTitle {
  a {
    color: var(--Neutral-900, #2E2E2E);
font-family: Neue-Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }
}
.smallChildItemName {
  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 26px;
  color: #ffffff;
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}
.childItemName {

  color: #2E2E2E;
font-family: Neue;
font-size: 14px;
font-style: normal;
// font-weight: 700;
line-height: 30px; /* 214.286% */
padding-left: 5px;
cursor: pointer;
&:hover {
  text-decoration: underline;
  color: #2E2E2E;
}
@media screen and (max-width: 768px) {
  line-height: 24px;
}
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background: #2E2E2E;
  position: relative;
  top: -2px;
  right: auto;
  bottom: auto;
}
.riskDisclaimer {
  color: var(--Neutral-400, #929292);
  text-align: justify;
  font-family: Neue-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.logoDmca {
  position: relative;
  width: 120px;
  height: auto;
  @media screen and (max-width: 576px) {
    width: 60px;
  }
}
.copyRight {
  width: 100%;
  padding: 16px 0 12px;
  margin-top: 40px;
  background-color: #F0F0F0;
  @media screen and (max-width: 576px) {
    margin-top: 12px;
    padding: 12px;
  }
}
.copyRightContent {
  width: 100%;
  max-width: 1336px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.license {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.licenseText {
  color: var(--Neutral-900, #2E2E2E);

/* Body/Medium/Regular */
font-family: Neue-Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 12px;
  }
}
.copyRightReserve {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--Neutral-900, #2E2E2E);

/* Body/Medium/Bold */
font-family: Neue;
font-size: 14px;
font-style: normal;
line-height: 20px; /* 142.857% */
  flex: none;
  @media screen and (max-width: 768px) {
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    padding-top: 20px;
  }
}
.break {
  display: none;
  @media screen and (max-width: 768px) {
    display: unset;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .childItem {
    min-width: 170px;
  }
}
.logosMobile {
  display: inline-flex;
  justify-content: space-evenly;
  padding: 10px 0 5px;
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
}
.logos {
  display: inline-flex;
  // justify-content: space-evenly;
  gap: 20px;
  padding-bottom: 50px;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 10px 0 5px;
  }
}
.separator {
  width: 0.5px;
  // height: 100%;
  background-color: #FFF;
}
.serviceForm {
  border-radius: 12px;
  background-color: #0872CA;
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
  }
}
.serviceList {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    gap: 8px;
  }
}
.serviceRegister {
  color: #FFF;
  font-family: Neue-Regular;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.serviceWrapper {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
.service {
  color: #FFF;
font-family: Neue;
font-size: 14px;
line-height: 20px; /* 142.857% */
}
.order{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #FFF;
  color: #2E2E2E;
  display: grid;
  span {
    place-self: center;
    font-family: Neue;
  }
}
.registerWrapper {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  flex-grow: 1;
}
.buttonLogin {
  width: 120px;
  height: 44px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  background-color: #FFF;
  color: #0872CA;
  box-shadow: 0px 4px 0px 0px rgba(255, 255, 255, 0.20);
  cursor: pointer;
  font-family: Neue;
  font-size: 14px;
  line-height: 20px;
}
.inputForm {
  width: 100%;
  height: 44px;
  border-radius: 8px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #FFF !important;
  border: solid 1px rgba(255, 255, 255, 0.20) !important;
  background-color: #0872CA !important;
  box-shadow: 0px 4px 0px 0px rgba(255, 255, 255, 0.20);
  &:hover {
    border: solid 1px #0872ca !important;
  }
  &:focus {
    border: solid 1px #0872ca !important;
  }
  &::placeholder {
    color: #FFF !important;
  }
}