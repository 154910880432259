.headerBanner {
  padding: 20px 30px;
  width: 100%;
  border-bottom: solid 1px #D8E1F5;
  min-height: calc(7.25vw + 33.5px);
  display: block;
  transition: transform 0.8s ease-in-out;
  transform: translateY(0); /* Initial position */
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #FFF;
  @media screen and (max-width: 1024px) {
    padding: 12px;
  }
  @media screen and (max-width: 768px) {
    min-height: calc(29vw + 25px);
  }
  @media screen and (min-width: 1487px) {
    min-height: 140px;
  }
}
.middleBanner {
  padding: 20px 30px;
  width: 100%;
  border-bottom: solid 1px #D8E1F5;
  min-height: calc(7.25vw + 33.5px);
  display: block;
  transition: transform 0.8s ease-in-out;
  @media screen and (max-width: 1024px) {
    padding: 12px;
  }
  @media screen and (max-width: 768px) {
    min-height: calc(29vw + 25px);
  }
  @media screen and (min-width: 1487px) {
    min-height: 140px;
  }
}
.customBanner {
  width: 100%;
  padding: 20px;
  display: block;
  @media screen and (max-width: 768px) {
    padding: 20px 0;
  }
}
.hide {
  position: unset;
}
