.Menu {
  width: auto;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0872ca;
  position: sticky;
  z-index: 10;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  transition: top 0.8s ease;
  @media screen and (max-width: 1024px) {
    height: 70px;
    box-shadow: unset;
  }
  @media screen and (max-width: 768px) {
    box-shadow: unset;
    height: 56px;
  }
}
.menuContent {
  width: 100%;
  max-width: 1336px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
}
.logo {
  width: 175px;flex: none;
    @media screen and (max-width: 768px) {
    width: 145px;
    

  }
}
// .mobileMenu {
//   @media screen and (min-width: 1024px) {
//     display: none;
//   }
// }
// .desktopMenu {
//   @media screen and (max-width: 1024px) {
//     display: none;
//   }
// }
