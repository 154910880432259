.ButonBackToTop {
  border-radius: 50%;
  background-color: #ff7300;
  height: 48px;
  width: 48px;
  display: block;
  cursor: pointer;
  margin-bottom: 8px;
  &:active {
    background-color: #ff7300;
  }
}
.Vector {
  margin: 0 auto;
  position: relative;
  top: 10px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid white;
  &::after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    background-color: white;
    -webkit-transform: rotate(0) translate(-2px, 4px);
    transform: rotate(0) translate(-2px, 4px);
    left: 0;
    position: relative;
    top: 0px;
  }
}
.TOP {
  margin: 16px auto 0 auto;
  width: 48px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: white;
}
