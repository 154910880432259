.iconTelegram, .iconWechat, .iconWhatsapp {
  height: 48px;
  width: 48px;
  cursor: pointer;
  border-radius: 50%;
  bottom: 24px;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
  margin: 0px 12px;
  overflow: visible;
  padding: 4px;
  position: fixed;
  top: auto;
  z-index: 2147483644;
  right: 20px;
  background-color: #ff7300;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 576px) {
    bottom: 40px;
  }
  &:hover {
    transition: all 1s ease-in-out;
    .telegramGroup, .appInvisible {
      transform: translateX(-5px);
      transition: all 0.5s ease-in-out;
      background-color: #ff7300;
      color: #FFF;
      visibility: visible !important;
    }
  }
}
.iconWhatsapp {
  background-color: unset;
  padding: 0;
}
.appVisible {
  background-color: #ff7300;
  width: 104px;
  padding: 2px;
  text-align: center;
  position: absolute;
  right: 54px;
  bottom: 0;
}
.telegramGroupVisible {
  background-color: #ff7300;
  border-radius: 111px;
  width: 295px;
  padding: 10px 12px;
  text-align: center;
  position: absolute;
  right: 50px;
  bottom: 0;
  color: #FFF;
  font-size: 16px;
  line-height: 26px;
}
.appInvisible {
  width: 104px;
  padding: 2px;
  text-align: center;
  position: absolute;
  right: 54px;
  bottom: 0;
  color: transparent;
  background-color: transparent;
  visibility: hidden;
}
.telegramGroup {
  border-radius: 111px;
  width: 225px;
  padding: 10px 12px;
  text-align: center;
  position: absolute;
  right: 45px;
  top: 0;
  color: transparent;
  font-size: 16px;
  line-height: 26px;
  background-color: transparent;
  visibility: hidden;
}