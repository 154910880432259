@font-face {
  font-family: Neue;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-75Bold.otf');
}
@font-face {
  font-family: Neue-Regular;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-65Medium.otf');
}
.backcomPopup {
  :global .ant-modal-body  {
    padding: 24px 40px;
  }
  :global .ant-form-item-label {
    color: var(--Neutral-500, #666);

/* Body/Medium/Regular */
font-family: Neue-Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  }
  :global .ant-modal-footer {
    border-top: unset;
    padding: 0 40px 40px;
    text-align: center;
  }

  :global .ant-select-selector {
    height: 52px !important;
    border-radius: 8px !important;
border: 1px solid var(--Alpha-Black-10, rgba(0, 0, 0, 0.10));
background-color: var(--Neutral-0, #FFF);
box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.10);
.ant-select-selection-placeholder {
  line-height: 50px;
}
  }
  :global .ant-select-selection-item {
    line-height: 52px !important;
    font-family: Neue;
    display: inline-flex;
    gap: 12px;

  }
  :global .ant-select-selection-search input {
    height: 100% !important;
    line-height: 1;
  }
}
.buttonLogin {
  width: 100%;
  height: 44px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  background-color: #0872CA;
  color: #FFF;
  box-shadow: 0px 4px 0px 0px #02569C;
border-bottom: 2px solid var(--Primary-600, #175B9E);
  cursor: pointer;
  font-family: Neue;
  font-size: 14px;
  line-height: 20px;
}
.title {
  color: var(--Secondary-500, #FF8104);
font-family: Neue;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 140% */
}
.desc {
  padding: 24px 0;
  color: var(--Neutral-900, #2E2E2E);

/* Body/Medium/Regular */
font-family: Neue-Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.inputForm {
  width: 100%;
  height: 52px;
  border-radius: 8px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #2E2E2E !important;
  
border: 1px solid var(--Alpha-Black-10, rgba(0, 0, 0, 0.10));
background-color: var(--Neutral-0, #FFF);
box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.10);
  &:hover {
    border: solid 1px #0872ca !important;
  }
  &:focus {
    border: solid 1px #0872ca !important;
  }
  &::placeholder {
    color: #FFF !important;
  }
}